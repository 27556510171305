import PropTypes from "prop-types";
import React from "react";
import AlertMessage from "empower/components/AlertMessage";

export const MESSAGE_SEVERITY = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
  DANGER: "danger",
};

export default class Alert extends React.Component {
  /**
   * @param {array} messages - items to be rendered
   * @return {String} HTML to be rendered
   */
  renderMessages(messages) {
    const { listClassName, severity, size, showCloseButton, onClose, title } =
      this.props;
    const message = messages.join(", ");
    if (message) {
      return (
        <AlertMessage
          containerClassName={`${listClassName} qa-messages__item ${
            messages.length > 1 ? "qa-messages__list" : ""
          } alert-notification-message alert-notification-message__container--${severity}`}
          message={message}
          icon={severity.toUpperCase()}
          iconSize={{ height: "20", width: "20" }}
          size={size}
          showCloseButton={showCloseButton}
          onClose={onClose}
          title={title}
        />
      );
    }

    return null;
  }

  render() {
    const { messages, id, className } = this.props;

    if (!(messages && messages.length > 0)) {
      return null;
    }

    return (
      <div className={className} id={id}>
        {this.renderMessages(messages)}
      </div>
    );
  }
}

Alert.propTypes = {
  className: PropTypes.string,
  severity: PropTypes.string,
  messages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  listClassName: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

Alert.defaultProps = {
  className: "",
  severity: "",
  messages: undefined,
  listClassName: "",
  id: undefined,
  size: "large",
  showCloseButton: false,
  onClose: PropTypes.func,
  title: "",
};
