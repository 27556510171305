import React from "react";
import PropTypes from "prop-types";
import Error from "svg-icons/AlertError.svg";
import Warning from "svg-icons/AlertWarning.svg";
import AlertInfo from "svg-icons/AlertInfo.svg";
import Success from "svg-icons/Success.svg";
import Delete from "svg-icons/Delete.svg";

const ICON_DETAILS = {
  ERROR: {
    IconComponent: Error,
    iconWidth: "20",
    iconHeight: "20",
    iconViewBox: "0 0 20 20",
    iconImageLabel: "Error",
  },
  WARNING: {
    IconComponent: Warning,
    iconWidth: "16",
    iconHeight: "15",
    iconViewBox: "0 0 16 15",
    iconImageLabel: "Warning",
  },
  INFO: {
    IconComponent: AlertInfo,
    iconWidth: "20",
    iconHeight: "20",
    iconViewBox: "0 0 20 20",
    iconImageLabel: "Information",
  },
  SUCCESS: {
    IconComponent: Success,
    iconWidth: "20",
    iconHeight: "20",
    iconViewBox: "0 0 20 20",
    iconImageLabel: "Success",
  },
};

const AlertMessage = ({
  containerClassName,
  iconClassName,
  messageClassName,
  message,
  icon,
  iconSize,
  size,
  showCloseButton,
  onClose,
  title,
}) => {
  if (!icon) {
    return (
      <div className={`${containerClassName}`} role={"alert"}>
        <span
          dangerouslySetInnerHTML={{ __html: message }}
          className={`alert-notification-message-container ${messageClassName}`}
        ></span>
      </div>
    );
  }

  const onCloseButtonClick = () => {
    if (onClose) {
      onClose();
    }
  };
  const { IconComponent, iconWidth, iconHeight, iconViewBox, iconImageLabel } =
    ICON_DETAILS[icon];

  return (
    <div className={`alert-notification__container ${containerClassName}`}>
      <div
        className={`alert-notification-message__container ${
          size === "large" ? "alert--large" : "alert--small"
        }`}
        role={"alert"}
      >
        <div className="iconContainer">
          <IconComponent
            aria-label={`${iconImageLabel} icon`}
            width={iconSize ? iconSize.width : iconWidth}
            height={iconSize ? iconSize.height : iconHeight}
            className={iconClassName}
            viewBox={
              iconSize
                ? `0 0 ${iconSize.width} ${iconSize.height}`
                : iconViewBox
            }
          />
        </div>
        <div className="alert-notification-message-title-container">
          {title && (
            <div
              className={`${messageClassName} alert-notification-message-title`}
            >
              {title}
            </div>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: message }}
            className={`${messageClassName} alert-notification-message-container`}
          ></div>
        </div>
        {showCloseButton && (
          <button
            type="button"
            className="alert-notification-message-closeButton"
            onClick={onCloseButtonClick}
          >
            <Delete
              className="alert-notification-message-closeButton-icon"
              aria-labelledby="Close Alert"
              height="10px"
              width="10px"
            />
          </button>
        )}
      </div>
    </div>
  );
};

AlertMessage.propTypes = {
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  messageClassName: PropTypes.string,
  iconSize: PropTypes.object,
  message: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(["ERROR", "WARNING", "INFO", "", "DANGER", "SUCCESS"]),
  size: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

AlertMessage.defaultProps = {
  containerClassName: "",
  iconClassName: "",
  messageClassName: "",
  icon: "WARNING",
  iconSize: undefined,
  size: "large",
  showCloseButton: false,
  onClose: undefined,
  title: "",
};

export default AlertMessage;
